export {default as getNextFriday} from './getNextFriday'
export {default as getNextSunday} from './getNextSunday'

export {default as fromISO} from './fromISO'

export {default as toYYYYMMDD} from './toYYYYMMDD'
export {default as toDDMMYYYY} from './toDDMMYYYY'
export {default as toMMDDYYYY} from './toMMDDYYYY'
export {default as fromYYYYMMDD} from './fromYYYYMMDD'
export {default as fromDDMMYYYY} from './fromDDMMYYYY'

export {default as fromFormat} from './fromFormat'
export {default as toFormat} from './toFormat'

export {default as addYears} from './addYears'
export {default as addDays} from './addDays'
export {default as substractYears} from './substractYears'

export {default as diffYears} from './diffYears'
export {default as differenceInDays} from './differenceInDays'
export {default as differenceInHours} from './differenceInHours'

export {default as createLocalDateFromString} from './createLocalDateFromString'

export function getYear(date: Date) {
  return date.getFullYear();
}

export function getMonth(date: Date) {
  return date.getMonth();
}
